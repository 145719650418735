.manage-container {
  width: 600px;
  margin: auto;
  position: relative;

  .manage-img-container {
    margin: 20px 0;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;

    img {
      padding: 10px 20px;
      border: 1px solid transparent;
      border-radius: 5px;
      transition: all 0.5s ease;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 600px) {
    width: 80%;
    img {
      display: block;
      margin: auto;
      margin-top: 10px;
    }

    .manage-img-container {
      flex-direction: column;
      gap: 5px;
    }

    .manage-img {
      max-width: 160px;
      height: auto;
    }
    .manage-img2 {
      max-width: 100px;
      height: auto;
    }
  }
}
