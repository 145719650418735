.tooltip {
  margin-left: 10px;
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 250px;
  text-align: center;
  padding: 5px 8px;
  border-radius: 6px;
  border: 1px solid #adb5bd;
  position: absolute;
  z-index: 1000;
  top: -50%;
  left: 105%;
  background-color: #fafafa;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.info-img {
  height: 35px;
  cursor: help;
}

@media screen and (max-width: 800px) {
  .tooltip .tooltiptext {
    top: 120%;
    left: -630%;
    background-color: white;
  }
}
