@import 'src/scss/variables.scss';

.textField {
  color: $primary-text-color;
}

.errorMessageText {
  text-align: center;
  font-size: 14px;
  color: red;
}