$primary-color: #143f67;
$secondary-color: #226db1;
$alternative-color: #256fa5;
$primary-text-color: #000;
$secondary-text-color: rgba(0, 0, 0, 0.6);
$alternative-text-color: #fff;
$primary-bg-color: #fff;
$secondary-bg-color: #fafafa;
$textField-bg-color: #eaeaea;
$primary-font-family: 'LatoRegular';
$weight-500: 500;
