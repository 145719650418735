.carrier-container {
  width: 700px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 25px;

  .carriers-img-container {
    display: flex;
    height: auto;
    justify-content: center;
    column-gap: 40px;

    > div {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .carrier-img {
      box-sizing: content-box;
      height: 60px;
    }
    .fedex-img {
      box-sizing: content-box;
      height: 40px;
    }
    .dhl-img {
      box-sizing: content-box;
      height: 40px;
    }

    img {
      padding: 20px;
      border: 1px solid transparent;
      border-radius: 5px;
      transition: all 0.5s ease;
      cursor: pointer;
    }
  }

  button {
    display: block;
    margin: auto;
    padding: 15px 30px;

    border: 1px solid #256fa5;
    border-radius: 10px;

    font-size: 16px;
    color: #256fa5;

    transition: all 0.5s ease;
    cursor: pointer;

    &:hover {
      background-color: #ced4da;
      transition: all 0.5s ease;
    }
  }

  @media screen and (max-width: 800px) {
    width: 80%;
    gap: 15px;

    .carriers-img-container {
      column-gap: 15px;

      .carrier-img {
        box-sizing: content-box;
        height: 30px;
      }
      .fedex-img {
        box-sizing: content-box;
        height: 25px;
      }
      .dhl-img {
        box-sizing: content-box;
        height: 20px;
      }
    }

    button {
      padding: 15px 30px;
    }
  }
}
