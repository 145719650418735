.plans-container {
  .plans-cards-container {
    margin: 30px 0;
    margin-bottom: 40px;

    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 30px;

    .plans-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      width: 250px;
      min-height: 450px;
      padding: 30px 15px;

      -webkit-box-shadow: 0px 0px 10px 0px rgba(37, 111, 165, 1);
      -moz-box-shadow: 0px 0px 10px 0px rgba(37, 111, 165, 1);
      box-shadow: 0px 0px 10px 0px rgba(37, 111, 165, 1);

      border-radius: 10px;

      cursor: pointer;
      transition: all 0.5s ease;

      &:hover {
        background-color: #f2f3f6;
        transition: all 0.5s ease;
      }

      .plans-card-title {
        font-size: 30px;
        color: #256fa5;
        text-align: center;
      }

      .plans-card-title2 {
        font-size: 24px;
        color: #256fa5;
        text-align: center;
      }
    }
  }

  @media screen and (max-width: 500px) {
    width: 80%;
    margin: auto;

    .plans-cards-container {
      flex-direction: column;
      gap: 30px;
      .plans-card {
        margin: auto;
        margin-top: 20px;
      }
    }
  }
}
