.signin-carrier-container {
  .carrier-img {
    display: block;
    max-width: 160px;
    margin: 25px auto;
  }

  @media screen and (max-width: 800px) {
    width: 80%;
    margin: auto;
  }
}
