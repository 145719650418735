.checkout-container {
  max-width: 1160px;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 40px;

  .checkout-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      color: #000;
      font-size: 30px;
    }

    p {
      color: #000;
      font-size: 18px;
    }

    span {
      color: #000;
      font-size: 26px;
      font-weight: 700;
    }
  }

  .checkout-cards-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 5px;

    .checkout-card {
      width: 500px;
      padding-left: 70px;

      .section-container {
        margin-top: 35px;

        .section-info-container {
          display: flex;
          align-items: center;
          column-gap: 10px;
        }

        h4 {
          color: #000;
          border-bottom: 1px solid #00000029;
          padding-bottom: 5px;
          margin-bottom: 20px;
        }
        p {
          color: #000;
        }

        .checkout-section-img-container {
          display: flex;
          align-items: center;
          column-gap: 30px;
          margin-top: 30px;

          img {
            display: block;
          }
        }
      }
    }
  }

  .billing-invoices-payment-container {
    margin-bottom: 34px;
    .billing-invoices-payment-card-name {
      display: flex;
      justify-content: space-between;
    }

    .billing-invoices-payment-card-info {
      margin-top: 12px;
      display: flex;
      flex-direction: column;

      img {
        display: block;
        height: 17px;
      }

      .billing-invoices-payment-preferred {
        display: flex;
        flex-direction: row;
        align-items: center;

        p {
          color: #299d4a;
          font-size: 18px;
          font-weight: bold;
        }

        img {
          display: block;
        }
      }
    }
  }

  .billing-invoices-add-payments-container {
    border-top: 1px solid #c4c4c4;
    margin-top: 20px;

    .billing-invoices-add-payments {
      margin-top: 20px;
      border: 1px solid #c4c4c4;
      border-radius: 10px;
      padding: 10px;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 800px) {
    width: 80%;
    margin: auto;
    padding-bottom: 20px;

    .checkout-title-container {
      display: inline-block;

      h3 {
        font-size: 26px;
      }

      p {
        font-size: 16px;
      }

      span {
        font-size: 18px;
        font-weight: 700;
      }
    }

    .checkout-cards-container {
      display: inline-block;

      .checkout-card {
        width: 100%;
      }
    }
  }

  @media screen and (min-width: 900px) {
    .checkout-cards-container {
      flex-direction: row;
    }
  }
}

.billing-invoices-add-payments-modal {
  width: 600px;
  background-color: #fff;

  padding: 20px 30px;

  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 3;

  border-radius: 10px;
  box-shadow: 0 0 0 100vmax rgba(0, 0, 0, 0.5);

  h3 {
    color: #000;
    font-size: 30px;
    text-align: center;
  }

  .billing-invoices-add-payments-modal-card-container {
    margin-top: 30px;
    padding: 20px 50px;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
  }

  .billing-invoices-add-payments-modal-card-container2 {
    margin-top: 30px;
    padding: 0 20px;

    border: 1px solid #c4c4c4;
    border-radius: 10px;

    cursor: pointer;
  }

  .billing-invoices-add-payments-modal-inp-container {
    display: flex;
    column-gap: 30px;
  }

  .billing-invoices-add-payments-modal-btns-container {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    column-gap: 30px;

    button {
      height: 50px;
      width: 200px;
      border-radius: 10px;
      border: none;
      font-size: 18px;
      cursor: pointer;
    }

    .billing-invoices-add-payments-modal-btn1 {
      background-color: #727272;
      color: #fff;
    }

    .billing-invoices-add-payments-modal-btn2 {
      background-color: #002f43;
      color: #fff;
    }
  }
}

.billing-invoices-success-payments-modal {
  width: 550px;
  background-color: #fff;

  padding: 20px 30px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;

  border-radius: 10px;
  box-shadow: 0 0 0 100vmax rgba(0, 0, 0, 0.5);
  text-align: center;
}
