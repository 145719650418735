.otpform-container {
  width: 440px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .otpform-code-container {
    margin-top: 50px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }

  @media screen and (max-width: 500px) {
    width: 100%;

    h2 {
      text-align: center;
    }
  }
}
