.billing-container {
  .billing-card-container {
    width: 600px;
    margin: 30px auto;
    margin-bottom: 25px;
    border: 1px solid #dee2e6;
    border-radius: 10px;
    img {
      display: block;
      margin: 10px auto;
      margin-bottom: 3px;
    }
  }

  .billing-inps-container {
    width: 600px;
    margin: 25px auto;
    display: flex;
    flex-direction: column;
    gap: 14px;

    .billing-inp-container {
      display: flex;
      column-gap: 20px;
    }
  }

  @media screen and (max-width: 800px) {
    width: 80%;
    margin: auto;

    .billing-card-container {
      width: 100%;
      margin: 5px auto;
      margin-top: 20px;
    }

    .billing-inps-container {
      width: 100%;
      margin: 20px auto;

      .billing-inp-container {
        column-gap: 10px;
      }
    }
  }
}
