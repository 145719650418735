.checkout-container {
  max-width: 1160px;
  margin: auto;

  .checkout-title-container {
    display: flex;
    align-items: flex-start;

    h3 {
      color: #000;
      font-size: 30px;
    }

    p {
      color: #000;
      font-size: 18px;
    }

    span {
      color: #000;
      font-size: 26px;
      font-weight: 700;
    }
  }

  .checkout-cards-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    // justify-content: space-between;

    .checkout-card {
      width: 500px;
      padding-left: 50px;

      .section-container {
        margin-top: 30px;

        .section-info-container {
          display: flex;
          align-items: center;
          column-gap: 10px;
        }

        h4 {
          color: #000;
          border-bottom: 1px solid #00000029;
          padding-bottom: 5px;
          margin-bottom: 20px;
        }
        p {
          color: #000;
        }

        .checkout-section-img-container {
          display: flex;
          align-items: center;
          column-gap: 30px;

          img {
            display: block;
          }
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    width: 80%;
    margin: auto;

    .checkout-title-container {
      display: inline-block;

      h3 {
        font-size: 26px;
      }

      p {
        font-size: 16px;
      }

      span {
        font-size: 18px;
        font-weight: 700;
      }
    }

    .checkout-cards-container {
      display: inline-block;

      .checkout-card {
        width: 100%;
      }
    }
  }

  @media screen and (min-width: 900px) {
    .checkout-cards-container {
      flex-direction: row;
    }
  }
}
